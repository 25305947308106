body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flexHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header,
main,
footer {
  padding-left: 300px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: auto !important;
}

.notBtn {
  display: flex;
  flex-direction: row-reverse
}

@media screen and (min-width:768px) {}

@media only screen and (max-width : 992px) {

  header,
  main,
  footer {
    padding-left: 0;
  }
}

.navbar.bg-dark {
  background-color: #636363 !important;
}
